import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import { ProductionsType, useSeeProductions } from "./hooks/useSeeProductions";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@/components/NewTable";
import { TableData } from "@/components/NewTable/type";
import TableContainer from "@/components/Container/TableContainer";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { quantityFormater } from "@/utils/number";
dayjs.extend(customParseFormat);

const columnHelper = createColumnHelper<ProductionsType>();

type ProductionsTableType = TableData<ProductionsType>;

export default function SeeProductions() {
  const { data } = useSeeProductions();
  const { data: seeProductions = [] } = data || {};

  const columns = [
    columnHelper.accessor("SK_producao", {
      header: "ID",
      id: "SK_producao",
      size: 100,
    }),
    columnHelper.accessor("FK_produto", {
      header: "Produto",
      id: "FK_produto",
      size: 150,
    }),
    columnHelper.accessor("dia", {
      header: "Dia",
      id: "dia",
      size: 140,
    }),
    columnHelper.accessor("codigo_linha_producao", {
      header: "Linha de produção/Tipo NF",
      id: "codigo_linha_producao",
      size: 200,
    }),
    columnHelper.accessor("producao_liquida", {
      header: "Produção líquida",
      id: "producao_liquida",
      size: 140,
      cell: ({ row }) => {
        return quantityFormater(Number(row.original.producao_liquida || 0));
      },
    }),
    columnHelper.accessor("codigo_fabrica", {
      header: "Código da fábrica",
      id: "codigo_fabrica",
      size: 200,
    }),
    columnHelper.accessor("base", {
      header: "Base",
      id: "base",
      size: 140,
    }),
    columnHelper.accessor("foi_entrada", {
      header: "Foi Entrada",
      id: "foi_entrada",
      size: 140,
    }),
    columnHelper.accessor("created_at", {
      header: "Data da Extração",
      id: "created_at",
      size: 140,
      cell: ({ row }) => {
        return dayjs(row.original.created_at).format("DD/MM/YYYY HH:mm:ss");
      },
    }),
  ];

  return (
    <AuthenticatedLayout>
      <TableContainer>
        <Table<ProductionsTableType>
          data={seeProductions}
          columns={columns}
          meta={{ layout: "stretch" }}
        />
      </TableContainer>
    </AuthenticatedLayout>
  );
}
