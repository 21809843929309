import dayjs from "dayjs";
import { CalculateInventoryProps, GetInventoryTypeProps } from "../types";
import { getConsumption } from "./consumption";
import { getPackaginDecreaseInventory } from "./decreaseInventory";
import { getCompositionInitialBalance } from "./initialBalance";
import { calculatePackagingSupplierDailyTotal } from "./packagingSupplier";
import { BASE } from "@/constants";

export function calculateInventory({
  initialBalance,
  packagingSupplierCellValues,
  realCollection,
  periodDays,
  packagings,
  realConsumption,
  consumptionForecast,
  packagingDecreaseInventory,
  isGrouped = true,
  selectedBase = BASE.GROUPED,
}: CalculateInventoryProps) {
  if (
    !realConsumption ||
    !initialBalance ||
    !packagingSupplierCellValues ||
    !periodDays ||
    !packagings ||
    !consumptionForecast ||
    !realCollection ||
    !packagingDecreaseInventory
  )
    return;

  const currentDay = dayjs();
  const inventoryPerComposition = packagings.map((packagingSupplier) => {
    const composition = String(packagingSupplier.composicao);
    let currentInventory = 0;
    const inventoryPerDay = periodDays.map((day) => {
      const { inventory, isNewInventory } = getCompositionInitialBalance({
        composition,
        day,
        currentInventory,
        initialBalance,
      });
      currentInventory = inventory;

      const { consumption } = getConsumption({
        currentDay,
        composition,
        consumptionForecast,
        day,
        realConsumption,
      });

      const { total } = calculatePackagingSupplierDailyTotal({
        isGrouped,
        selectedBase,
        currentInventory,
        currentDay,
        composition,
        day,
        packagingSupplierCellValues,
        realCollection,
      });

      const { decrease } = getPackaginDecreaseInventory({
        composition,
        day,
        packagingDecreaseInventory,
      });

      const dailyBalance = getDailyBalance({
        quantityInput: total,
        quantityOutput: consumption,
        decreaseInventory: decrease,
      });

      const inventoryForDate = createInventoryForDate({
        currentInventory,
        remainingInventory: dailyBalance,
        date: day,
        isNewInventory,
        valuesToDecreaseFromActual: {
          decreaseInventory: decrease,
        },
      });
      currentInventory = dailyBalance;

      return inventoryForDate;
    });
    return {
      composicao: packagingSupplier.composicao,
      inventario: inventoryPerDay,
    };
  });

  return inventoryPerComposition;
}

type CalculateDayInventoryProps = {
  quantityInput: number;
  quantityOutput: number;
  decreaseInventory: number;
};
function getDailyBalance({
  quantityInput,
  quantityOutput,
  decreaseInventory,
}: CalculateDayInventoryProps) {
  return quantityInput - quantityOutput - decreaseInventory;
}

export function getPackagingInventory({
  composition,
  day,
  inventory,
}: GetInventoryTypeProps) {
  const compositionInventory = inventory?.find(
    (item) => item?.composicao == composition
  );
  const inventoryDay =
    compositionInventory?.inventario.find((item) => item[day]) || {};

  return {
    dia: day,
    ...inventoryDay[day],
  };
}

export function createInventoryForDate({
  currentInventory,
  remainingInventory,
  date,
  isNewInventory,
  valuesToDecreaseFromActual = {
    decreaseInventory: 0,
  },
}: {
  currentInventory: number;
  remainingInventory: number;
  date: string;
  isNewInventory?: boolean;
  valuesToDecreaseFromActual?: {
    decreaseInventory: number;
  };
}) {
  return {
    [date]: {
      novo: isNewInventory,
      atual: currentInventory - valuesToDecreaseFromActual.decreaseInventory,
      restante: remainingInventory,
    },
  };
}
