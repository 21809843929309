import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { TEXT_COLOR } from "@/constants";
import SimulationForm from "@/pages/Simulacoes/components/Form";
import {
  schema,
  SimulationFormType,
} from "@/pages/Simulacoes/components/Form/zod";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoIosFunnel } from "react-icons/io";
import { useProductionPlanContext } from "../../context";

export const CURRENT_DAY = dayjs().toDate();
export const FORTY_FIVE_DAYS = dayjs().add(45, "day").toDate();

type FilterDropdownProps = {
  fkFactory: string;
};

export default function FilterDropdown({ fkFactory }: FilterDropdownProps) {
  const [visible, setVisible] = useState(false);
  const { handleFilterProductionPlan } = useProductionPlanContext();
  const form = useForm<SimulationFormType>({
    resolver: zodResolver(schema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  useEffect(() => {
    form.reset({
      periodo_inicio: CURRENT_DAY,
      periodo_fim: FORTY_FIVE_DAYS,
      FK_fabrica: fkFactory,
    });
  }, []);

  return (
    <DropdownMenu open={visible} onOpenChange={() => setVisible(!visible)}>
      <DropdownMenuTrigger asChild>
        <Button size="icon" variant="outline">
          <IoIosFunnel size={20} color={TEXT_COLOR} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="p-4">
        <SimulationForm
          visibleSegregation={true}
          visibleObservation={false}
          form={form}
          onSubmit={() => {
            handleFilterProductionPlan({
              periodo_fim: form.getValues().periodo_fim,
              periodo_inicio: form.getValues().periodo_inicio,
              FK_fabrica: form.getValues().FK_fabrica,
              produtos: form.getValues().produtos,
              recursos: form.getValues().recursos,
              segregado: form.getValues().segregado,
              visualizar_base: form.getValues().visualizar_base,
              tipo_planejamento: form.getValues().tipo_planejamento,
            });
            setVisible(!visible);
          }}
          button={{ disabled: false, title: "Filtrar" }}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
