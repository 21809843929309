import { quantityFormater } from "@/utils/number";
import {
  AVERAGE_SALE_ST,
  DEMAND_PLANNING_ST,
  LINE_CELL,
} from "../../constants";
import {
  getAccumulatedSaleCell,
  getCellLineValue,
  getGridSaleCell,
  getLineIndisponibility,
  getProductRealProduction,
  isNewInventoryCellValue,
} from "../../utils/cell";
import { getResourceFactoryProduct } from "../../utils/inventory/line";
import { getAccumulatedRealProduction } from "../../utils/inventory/realProduction";
import { getProduct } from "../../utils/product";
import { getInventoryProduct } from "../../utils/simulation";
import IndisponibilityLineCell from "./IndisponibilityLineCell";
import LineCell from "./LineCell";
import Cell from "./Cell";
import Empty from "@/components/Empty";
import { EMPTY_VALUE, QUARENTINE_LINE } from "@/constants";
import {
  CustomDemandPlanningType,
  useSimulationProductionContext,
} from "../../context";
import {
  AccumulatedProductionType,
  FindProductInventoryType,
  GetProductInventoryType,
  IndisponibilityType,
  ProductGridSaleType,
  SaleAccumulatedType,
} from "../../types";
import {
  CellLineValuesType,
  DemandEstimateResponseType,
  ProductLineType,
  ProductRealProductionType,
} from "@/hooks/useProductionSimulationMutation";
import { memo, useMemo } from "react";
import { getDemandPlanning } from "../../utils/demandPlanning";

type SimulationItemsCellProps = {
  factoryCode: string;
  segregated: boolean;
  index: number;
  fkProduct: string;
  currentDay: string;
  segregatedRealProduction: Array<ProductRealProductionType>;
  segregatedGridSale: Array<ProductGridSaleType>;
  line: {
    st: string;
    tipo: string;
    factoryResourceFk: string | number;
    skResourceFactoryProduct: string | number;
    quantity: string | number;
    isIndisponibility: boolean;
    disabled?: boolean;
  };
  simulation: {
    inventory?: Array<FindProductInventoryType>;
    accumulatedSale: Array<SaleAccumulatedType>;
    accumulatedRealProduction: Array<AccumulatedProductionType>;
    indisponibilityLine: Array<IndisponibilityType>;
    lineValues: Array<CellLineValuesType>;
    productGridSaleObject: Array<ProductGridSaleType>;
    productInventoryObject: Array<GetProductInventoryType>;
    productRealProductionObject: Array<ProductRealProductionType>;
    resourceFactoryProductsObject: Array<ProductLineType>;
    demandPlanning: Array<DemandEstimateResponseType>;
    customDemandPlanning: CustomDemandPlanningType;
  };
};

const SimulationItemsCell = ({
  currentDay,
  fkProduct,
  index,
  segregated,
  segregatedRealProduction,
  segregatedGridSale,
  factoryCode,
  line: {
    st,
    tipo,
    factoryResourceFk,
    quantity,
    isIndisponibility,
    skResourceFactoryProduct,
    disabled,
  },
  simulation: {
    inventory,
    accumulatedSale,
    accumulatedRealProduction,
    indisponibilityLine,
    lineValues,
    productGridSaleObject,
    productInventoryObject,
    productRealProductionObject,
    resourceFactoryProductsObject,
    demandPlanning,
    customDemandPlanning,
  },
}: SimulationItemsCellProps) => {
  const {
    lines: { handleLineValues },
    production: { handleAccumulatedProduction },
  } = useSimulationProductionContext();
  const productInventory = useMemo(() => {
    return getInventoryProduct({
      day: currentDay,
      inventory,
      product: fkProduct,
    });
  }, []);

  const accumulatedSaleValue = useMemo(() => {
    return getAccumulatedSaleCell({
      day: currentDay,
      accumulatedSale,
      productFk: fkProduct,
    });
  }, []);

  const accumulatedRealProductionValue = useMemo(() => {
    return getAccumulatedRealProduction({
      accumulatedRealProduction,
      day: currentDay,
      productFk: fkProduct,
    });
  }, []);

  const lineIndisponibility = useMemo(() => {
    return getLineIndisponibility({
      factoryResourceFk,
      currentDay,
      indisponibilityLine,
    });
  }, []);

  const lineValue = useMemo(() => {
    return getCellLineValue({
      cellLineValues: lineValues,
      currentDay,
      factoryResourceFk,
      productFk: fkProduct,
    });
  }, []);

  const gridSaleValue = useMemo(() => {
    return getGridSaleCell({
      gridSale: productGridSaleObject,
      day: currentDay,
      productFk: fkProduct,
    });
  }, []);

  const isNewInventoryValue = useMemo(() => {
    return isNewInventoryCellValue({
      day: currentDay,
      inventory: productInventoryObject,
      productFk: fkProduct,
    });
  }, []);

  const productRealProduction = useMemo(() => {
    return getProductRealProduction({
      day: currentDay,
      productFk: fkProduct,
      productRealProduction: productRealProductionObject,
    });
  }, []);

  const resourceFactoryProduct = useMemo(() => {
    return getResourceFactoryProduct({
      resourcesFactoriesProducts: resourceFactoryProductsObject,
      skResourceFactoryProduct: String(skResourceFactoryProduct),
    });
  }, []);

  const demandPlanningDay = useMemo(() => {
    return getDemandPlanning({
      customDemandPlanning,
      demandPlanning,
      fkProduct,
      day: currentDay,
    });
  }, []);

  const segregatedRealProductionValue = useMemo(() => {
    return getProductRealProduction({
      day: currentDay,
      productFk: fkProduct,
      productRealProduction: segregatedRealProduction,
    });
  }, []);

  const segregatedGridSaleValue = useMemo(() => {
    return getGridSaleCell({
      gridSale: segregatedGridSale,
      day: currentDay,
      productFk: fkProduct,
    });
  }, []);

  const isDemandPlanning =
    st?.toUpperCase() == DEMAND_PLANNING_ST.toUpperCase();
  const isLineCellType = tipo?.toUpperCase() == LINE_CELL.toUpperCase();
  const isAvergeSaleType = tipo?.toUpperCase() == AVERAGE_SALE_ST.toUpperCase();

  function handleLineCellChange(value?: string, cellValue?: string) {
    handleLineValues({
      dia: currentDay,
      valor: value,
      FK_fabrica_recurso: factoryResourceFk,
      FK_produto: fkProduct,
    });
    if (
      segregatedRealProductionValue == 0 &&
      factoryResourceFk == QUARENTINE_LINE
    ) {
      handleAccumulatedProduction({
        dia: currentDay,
        FK_produto: fkProduct,
        valor: Number(value) || 0,
        valor_anterior: Number(cellValue) || 0,
      });
    }
  }

  if (isIndisponibility && isAvergeSaleType) return <Empty />;
  if (isIndisponibility && isDemandPlanning) return <Empty />;
  if (lineIndisponibility.length !== EMPTY_VALUE) {
    return (
      <IndisponibilityLineCell
        index={index}
        product={getProduct(demandPlanning, fkProduct)}
        cellValue={quantityFormater(Number(lineValue))}
        indisponibilityLine={lineIndisponibility}
        onChange={handleLineCellChange}
        line={resourceFactoryProduct}
        disabled={disabled}
      />
    );
  }
  if (isLineCellType && factoryResourceFk) {
    const {
      extra: { volume },
    } = getProduct(demandPlanning, fkProduct);
    return (
      <LineCell
        volume={volume}
        cellValue={lineValue}
        index={index}
        disabled={disabled}
        currentDay={currentDay}
        fkProduct={fkProduct}
        fkFactoryResource={Number(factoryResourceFk)}
        onChange={handleLineCellChange}
      />
    );
  }

  return (
    <Cell
      factoryCode={factoryCode}
      segregated={segregated}
      fkProduct={fkProduct}
      st={st}
      day={currentDay}
      segregatedRealProduction={Number(segregatedRealProductionValue)}
      segregatedGridSale={Number(segregatedGridSaleValue)}
      realProduction={Number(productRealProduction)}
      accumulatedProduction={Number(accumulatedRealProductionValue)}
      acumulatedSale={Number(accumulatedSaleValue)}
      averageSale={Number(quantity)}
      demandPlanning={Number(demandPlanningDay)}
      gridSale={Number(gridSaleValue)}
      balance={{
        final: Number(productInventory.restante),
        initial: Number(productInventory.atual),
        isNew: isNewInventoryValue,
      }}
    />
  );
};

export default memo(SimulationItemsCell);
