import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export type ResourceWithProductsType = {
  FK_produto: string;
  codigo_linha_de_producao: string;
  SK_fabrica_recurso: number;
  SK_recurso: number;
};

async function fecthResourceWithProducts() {
  const response = await instance.post<ApiResponse<ResourceWithProductsType>>(
    "/planningbeer/resourceFactoryProduct/products"
  );

  return response.data;
}

export function useResourceWithProducts() {
  return useQuery({
    queryKey: ["resourceWithProducts"],
    queryFn: fecthResourceWithProducts,
  });
}
