import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import SimulationTable from "@/pages/Simulacoes/components/Table/SimulationTable";
import { useProductionPlanContext } from "../context";
import Text from "@/components/Text/Text";
import InvalidProductionPlanError from "@/pages/Simulacoes/components/Error/InvalidProductionPlanError";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";
import { useProductionPlanPatchMutation } from "@/hooks/useProductionPlanPatchMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants";

export default function EditProductionPlan() {
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useProductionPlanPatchMutation();
  const {
    productionPlan: {
      descricao,
      revisao,
      SK_plano_producao,
      valores_linhas,
      FK_fabrica,
    },
  } = useProductionPlanContext();
  const {
    lines: { lineValues },
    clear: clearSimulationProduction,
    customDemandPlanning: { customDemandPlanning },
  } = useSimulationProductionContext();

  async function handleSave() {
    try {
      await mutateAsync({
        skProductionPlan: SK_plano_producao,
        revision: String(revisao),
        lineValues: JSON.stringify(lineValues),
        oldLineValues: JSON.stringify(valores_linhas || []),
        customDemandPlanning: customDemandPlanning,
        fkFactory: FK_fabrica,
      });
      toast.success("Plano de Produção atualizado com sucesso!");
      clearSimulationProduction();
      return navigate("/" + ROUTES.productionPlan.index);
    } catch (error) {
      return validationError(error);
    }
  }

  if (!SK_plano_producao) return <InvalidProductionPlanError />;
  return (
    <AuthenticatedLayout title="Editando Plano de Produção">
      <Row>
        <Text className="text-base">
          Plano de Produção: <strong>{SK_plano_producao}</strong>, &nbsp;
        </Text>
        <Text className="text-base">
          <strong>{descricao}</strong>,&nbsp;
        </Text>
        <Text className="text-base">
          Versão: <strong>{revisao}</strong>
        </Text>
      </Row>
      <SimulationTable />
      <Row justify="end" className="gap-4 mt-2">
        <Button
          size="sm"
          onClick={() => {
            navigate(-1);
          }}
          variant="outline"
        >
          Cancelar
        </Button>
        <Button size="sm" onClick={handleSave} disabled={isPending}>
          Salvar
        </Button>
      </Row>
    </AuthenticatedLayout>
  );
}
