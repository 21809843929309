import { DatePicker } from "@/components/DatePicker";
import Row from "@/components/Row";
import ProductSelect from "@/components/Select/ProductsSelect";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { PLANNING_TYPE, REQUIRED_FIELD, TEXT_COLOR } from "@/constants";
import useProducts from "@/hooks/useProducts";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IoMdTrendingUp } from "react-icons/io";
import { z } from "zod";
import { useSimulationProductionContext } from "../../context";
import Empty from "@/components/Empty";
import dayjs, { Dayjs } from "dayjs";
import { useLocation } from "react-router-dom";

const schema = z.object({
  produtos: z.array(z.string({ required_error: REQUIRED_FIELD })),
  data_inicio: z.date({ required_error: REQUIRED_FIELD }),
  data_fim: z.date({ required_error: REQUIRED_FIELD }),
  quantidade: z.string({ required_error: REQUIRED_FIELD }),
});

type FormData = z.infer<typeof schema>;

export default function CustomDemandPlanningPopover() {
  const isViewProductionPlan = useLocation().pathname.includes("visualizar");
  const {
    simulationData: {
      simulador_producao: { periodo_fim, tipo_planejamento },
    },
    customDemandPlanning: { handleCustomDemandPlanning },
  } = useSimulationProductionContext();

  const { isPending } = useProducts();
  const form = useForm<FormData>({
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: zodResolver(schema),
  });
  const nextDay = dayjs().add(1, "day").toDate();
  const limitDay = dayjs(periodo_fim).toDate();

  function handleProductsChange(value) {
    const previousValue = form.getValues("produtos") || [];

    if (previousValue.includes(value)) {
      const newValues = previousValue.filter((item) => item !== value);
      return form.setValue("produtos", newValues);
    }
    const values = [...previousValue, value];
    form.setValue("produtos", values);
  }

  function handleFormSubmit(data: FormData) {
    const startDate = dayjs(data.data_inicio);
    const endDate = dayjs(data.data_fim);

    const generateDateRange = (start: Dayjs, end: Dayjs) => {
      const dates: Array<string> = [];
      let current = start;

      while (current.isBefore(end) || current.isSame(end)) {
        dates.push(current.format("YYYY-MM-DD"));
        current = current.add(1, "day");
      }

      return dates;
    };
    const dateRange = generateDateRange(startDate, endDate);

    const formatedToSubmit = data.produtos.flatMap((produto) =>
      dateRange.map((day) => ({
        fkProduto: produto,
        demand: Number(data.quantidade),
        day,
      }))
    );

    handleCustomDemandPlanning(formatedToSubmit);
    return;
  }

  useEffect(() => {
    form.reset();
    return () => {
      form.reset();
    };
  }, []);

  if (
    isPending ||
    tipo_planejamento == PLANNING_TYPE.COMMERCIAL ||
    isViewProductionPlan
  )
    return <Empty />;
  return (
    <Popover onOpenChange={() => form.reset()}>
      <PopoverTrigger>
        <IoMdTrendingUp size={22} color={TEXT_COLOR} />
      </PopoverTrigger>
      <PopoverContent className="w-fit ml-6 mt-2">
        <Form {...form}>
          <form
            className="flex flex-col gap-4"
            onSubmit={form.handleSubmit(handleFormSubmit)}
          >
            <FormField
              control={form.control}
              name="produtos"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Produtos *</FormLabel>
                  <FormControl>
                    <ProductSelect
                      handleSelected={handleProductsChange}
                      selected={field.value || []}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Row className="gap-2 w-full">
              <FormField
                control={form.control}
                name="data_inicio"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Data início *</FormLabel>
                    <FormControl>
                      <DatePicker
                        minDate={nextDay}
                        maxDate={limitDay}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="data_fim"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Data fim *</FormLabel>
                    <FormControl>
                      <DatePicker
                        minDate={nextDay}
                        maxDate={limitDay}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Row>
            <FormField
              control={form.control}
              name="quantidade"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Quantidade *</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      onChange={field.onChange}
                      value={field.value}
                      defaultValue={0}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Row justify="end">
              <Button>Aplicar</Button>
            </Row>
          </form>
        </Form>
      </PopoverContent>
    </Popover>
  );
}
