import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export type ProductionsType = {
  SK_producao: string;
  FK_produto: string;
  codigo_linha_producao: string;
  producao_liquida: string;
  codigo_fabrica: string;
  dia: string;
  base: string;
  created_at: string;
  foi_entrada: string;
};

export async function fetchProductions() {
  const response = await instance.post<ApiResponse<ProductionsType>>(
    "/planningbeer/seeProductions"
  );
  return response.data;
}

export function useSeeProductions() {
  return useQuery({
    queryKey: ["seeProductions"],
    queryFn: fetchProductions,
  });
}
