import { SelectProps } from "@radix-ui/react-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { PLANNING_TYPE } from "@/constants";

export default function PlanningTypeSelect(props: SelectProps) {
  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue defaultValue="Janeiro" placeholder="Janeiro" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={PLANNING_TYPE.COMMERCIAL}>Comercial</SelectItem>
        <SelectItem value={PLANNING_TYPE.LOGISTICS}>Logística</SelectItem>
      </SelectContent>
    </Select>
  );
}
