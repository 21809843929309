import { DatePicker } from "@/components/DatePicker";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { REQUIRED_FIELD, TEXT_COLOR } from "@/constants";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useProductionPlanContext } from "../../context";
import { CURRENT_DAY, FORTY_FIVE_DAYS } from "../Dropdown/FilterDropdown";
import { generateDisponibilitySheet } from "../../utils/sheet";
import { toast } from "sonner";
import { MdOutlineInventory } from "react-icons/md";

const schema = z.object({
  dia: z.date({ required_error: REQUIRED_FIELD }),
});

type ProductDisponibilityFormType = z.infer<typeof schema>;

export default function ProductDisponibilityModal() {
  const {
    inventory: { getInventory },
    simulationData: {
      simulador_producao: productionSimulator,
      previsao_demanda: demandPlanning,
      fabrica: { codigo: factoryCode } = { codigo: "" },
    },
  } = useSimulationProductionContext();
  const {
    filterProductionPlan: {
      periodo_inicio = CURRENT_DAY,
      periodo_fim = FORTY_FIVE_DAYS,
    },
  } = useProductionPlanContext();
  const {
    produtos_producao_real: productsRealProduction,
    produtos_venda_grade: productsGridSale,
  } = productionSimulator || {};
  const form = useForm<ProductDisponibilityFormType>({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const inventory = getInventory();

  function handleGenerate() {
    const date = dayjs(form.getValues("dia")).format("YYYY-MM-DD");
    const brDate = dayjs(form.getValues("dia")).format("DD/MM/YYYY");
    const productDisponibilityForDate = inventory.map((item) => {
      const { inventario, produto } = item || {};
      const itemInventory =
        inventario?.find((inv) => inv?.[date.toString()]) || {};
      const { atual, restante } = itemInventory[date.toString()] || {};

      const realProduction = productsRealProduction
        ?.filter((productRealProduction) => {
          return (
            productRealProduction.dia == date &&
            productRealProduction.FK_produto == produto
          );
        })
        ?.reduce((acc, { valor }) => ({ valor: acc.valor + Number(valor) }), {
          valor: 0,
        });

      const productName = demandPlanning?.find(
        ({ FK_produto }) => produto == FK_produto
      )?.descricao;

      const gridSale = productsGridSale?.find((productGridSale) => {
        return (
          productGridSale.dia == date && productGridSale.FK_produto == produto
        );
      });

      return {
        produto: produto || "",
        descricao: productName || "",
        producao_real: realProduction?.valor || 0,
        venda_grade: gridSale?.valor || 0,
        inventario: {
          atual: atual || 0,
          restante: restante || 0,
        },
      };
    });

    if (productDisponibilityForDate.length == 0)
      return toast.error("Não há produtos disponíveis para a data selecionada");
    generateDisponibilitySheet(
      productDisponibilityForDate,
      brDate,
      factoryCode
    );
  }

  useEffect(() => {
    form.reset({ dia: undefined });
    () => {
      return form.reset({ dia: undefined });
    };
  }, []);

  return (
    <Dialog>
      <DialogTrigger>
        <Button variant="ghost" className="w-full">
          <Row align="center">
            <MdOutlineInventory size={19} color={TEXT_COLOR} />
            <Text className="ml-1 mt-1">Disponibilidade dos Produtos</Text>
          </Row>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <Text className="text-lg font-bold">Disponibilidade dos Produtos</Text>
        <Text>
          Período do Plano de Produção{" "}
          {dayjs(periodo_inicio).format("DD/MM/YYYY")} -{" "}
          {dayjs(periodo_fim).format("DD/MM/YYYY")}
        </Text>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleGenerate)}>
            <FormField
              control={form.control}
              name="dia"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Dia *</FormLabel>
                  <FormControl>
                    <DatePicker
                      {...field}
                      maxDate={periodo_fim}
                      minDate={periodo_inicio}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Row justify="end" className="mt-4">
              <Button>Gerar</Button>
            </Row>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
