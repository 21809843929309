import TableContainer from "@/components/Container/TableContainer";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import { GridSalesType, useGridSales } from "./hooks/useGridSales";
import dayjs from "dayjs";
import { createColumnHelper } from "@tanstack/react-table";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TableData } from "@/components/NewTable/type";
import Table from "@/components/NewTable";
import { quantityFormater } from "@/utils/number";
dayjs.extend(customParseFormat);

const columnHelper = createColumnHelper<GridSalesType>();

type GridSaleTableType = TableData<GridSalesType>;

export default function SeeGridSale() {
  const { data } = useGridSales();
  const { data: gridSales = [] } = data || {};

  const columns = [
    columnHelper.accessor("SK_venda_grade", {
      header: "ID",
      id: "SK_venda_grade",
      size: 100,
    }),
    columnHelper.accessor("FK_produto", {
      header: "Produto",
      id: "FK_produto",
    }),
    columnHelper.accessor("valor", {
      header: "Quantidade",
      id: "valor",
      cell: ({ row }) => {
        return quantityFormater(Number(row.original.valor || 0));
      },
    }),
    columnHelper.accessor("dia", {
      header: "Dia",
      id: "dia",
    }),
    columnHelper.accessor("base", {
      header: "Base",
      id: "base",
    }),
    columnHelper.accessor("filial_origem", {
      header: "Filial",
      id: "filial_origem",
    }),
    columnHelper.accessor("created_at", {
      header: "Data da Extração",
      id: "created_at",
      cell: ({ row }) =>
        dayjs(row.original.created_at).format("DD/MM/YYYY HH:mm:ss"),
    }),
  ];
  return (
    <AuthenticatedLayout>
      <TableContainer>
        <Table<GridSaleTableType>
          columns={columns}
          data={gridSales}
          meta={{ layout: "stretch" }}
        />
      </TableContainer>
    </AuthenticatedLayout>
  );
}
