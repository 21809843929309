import Empty from "@/components/Empty";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { REQUIRED_FIELD, TEXT_COLOR } from "@/constants";
import useInventoryMutation from "@/hooks/useInventoryMutation";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";
import { validationError } from "@/utils/api";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { MdInventory } from "react-icons/md";
import { toast } from "sonner";
import { z } from "zod";

const schema = z.object({
  descricao: z.string({ required_error: REQUIRED_FIELD }),
});

type FormValues = z.infer<typeof schema>;

export default function CreateInventoryButton() {
  const {
    inventory,
    simulationData: {
      simulador_producao: { periodo_fim, FK_fabrica },
      tipo_base,
    },
  } = useSimulationProductionContext();
  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const { mutateAsync, isPending } = useInventoryMutation();
  const [open, setOpen] = useState(false);
  const dayToCreateInventory = dayjs(periodo_fim).add(1, "day");

  function getProductFinalBalance() {
    const productsFinalBalance = inventory.getInventory().map((product) => {
      const lastInventory = product?.inventario.find((entry) => {
        return Object.keys(entry)[0] == periodo_fim;
      });
      const saldoFinal = lastInventory
        ? lastInventory[periodo_fim].restante
        : 0;

      return {
        Código: product?.produto || "",
        Quantidade: saldoFinal,
      };
    });
    return productsFinalBalance;
  }

  async function createInventory(data: FormValues) {
    if (isPending) return;
    const inventoryToCreate = getProductFinalBalance();
    try {
      await mutateAsync({
        base: String(tipo_base),
        FK_fabrica: String(FK_fabrica),
        descricao: data.descricao,
        vigencia: dayToCreateInventory.format("YYYY-MM-DD"),
        sheetRows: inventoryToCreate,
      });
      setOpen((prev) => !prev);
      return toast.success("Inventário criado com sucesso!");
    } catch (er) {
      validationError(er);
    }
  }

  if (!inventory) return <Empty />;
  return (
    <Dialog open={open} onOpenChange={() => setOpen((prev) => !prev)}>
      <DialogTrigger>
        <Button variant="ghost" className="w-full">
          <Row align="center" justify="start" className="w-full">
            <MdInventory color={TEXT_COLOR} size={20} />
            <Text className="mt-1 ml-1">Criar Inventário</Text>
          </Row>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <Text>
          Ao confirmar será criado um novo inventário para a base{" "}
          {tipo_base == "BASE1" ? "Padrão" : "Estratégico"} com a vigência de{" "}
          {dayToCreateInventory.format("DD/MM/YYYY")}.
        </Text>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(createInventory)}>
            <FormField
              control={form.control}
              name={"descricao"}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Descrição *</FormLabel>
                  <FormControl>
                    <Textarea onChange={field.onChange} value={field.value} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Row justify="end" className="mt-4">
              <Button disabled={isPending}>Criar</Button>
            </Row>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
