import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

const FUSO_HORARIO = 3;

export function formatUTCDate(date?: string) {
  if (!date) return undefined;
  const dateObj = new Date(date);
  dateObj.setHours(dateObj.getHours() + FUSO_HORARIO);
  return dateObj;
}

export function addDashToDate(date: string) {
  const productionDate = String(date);
  const year = productionDate?.substring(0, 4);
  const month = productionDate?.substring(4, 6);
  const day = productionDate?.substring(6, 8);

  return `${year}-${month}-${day}`;
}

export function isBetweenDates({ initialPeriod, finalPeriod, date }) {
  if (!date) return false;
  return dayjs(date).isBetween(initialPeriod, finalPeriod, null, "[]");
}
