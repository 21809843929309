import instance from "@/api/business";
import { ResponseDataType } from "@/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { packagingSimulatorKeys } from "./usePackagingSimulators";

// type ProductionType = {
//   [key: string]: { total: number; meta: { isRealProduction: boolean } };
// };

export type OptionsType = {
  options: {
    isEditable: boolean;
    total?: number | string;
  };
};

export type PackagingSupplierCellValueType = {
  SK_simulacao_embalagem_fornecedor?: number;
  FK_modelo_carga?: string;
  quantidade_palete_carga?: string;
  quantidade_por_palete?: string;
  FK_simulacao_producao?: number;
  FK_embalagem_fornecedor: number;
  FK_fabrica: number;
  composicao: string;
  dia: string;
  valor: string;
};

export type InitialBalanceType = {
  FK_produto: string;
  SK_inventario: string;
  filial_origem: string;
  quantidade: string;
  vigencia: string;
};

export type ConsumptionType = {
  composicao: string;
  dia: string;
  producao_total: number;
  previsao_consumo_total_componentes: number;
  quantidade_ocmponentes_individual: string;
  producao_total_individual?: string;
  producao_individual?: string;
  meta?: {
    simulado: boolean;
  };
};

export type RealCollectionType = {
  dia: string;
  total: number;
  produto: string;
};

export type RealCollectionAccumulatedType = {
  [composition: string]: {
    coleta_acumulado: number;
    dias: {
      [date: string]: number;
    };
  };
};

export type RealConsumptionType = {
  SK_consumo: string;
  componente: string;
  dia: string;
  quantidade: string;
  codigo_fabrica: string;
};

export type RealConsumptionAccumulatedType = {
  [composition: string]: {
    consumo_acumulado: number;
    dias: {
      [date: string]: number;
    };
  };
};

export type PackagingDecreaseInventoryType = {
  quantidade: number;
  FK_embalagem: string;
  dia: string;
};

export type PackagingSimulationMetaDataType = {
  filtros: {
    base_selecionada?: string;
    esta_agrupado: boolean;
  };
  valores_fornecedores: Array<PackagingSupplierCellValueType>;
  consumo_acumulado: RealConsumptionAccumulatedType;
  coleta_acumulado: RealCollectionAccumulatedType;
  saldo_inicial: Array<InitialBalanceType>;
  previsao_consumo: Array<ConsumptionType>;
  consumo_real: Array<RealConsumptionType>;
  coleta_real: Array<RealCollectionType>;
  baixa_embalagem: Array<PackagingDecreaseInventoryType>;
};

export type RowType = {
  st: string;
} & OptionsType;

export type SupplierTypeMeta = {
  meta?: {
    indisponibilidade_lead_time?: Array<string>;
    autorizacao_entrega?: Array<AuthorizationDeliveryType>;
  };
};

export type AuthorizationDeliveryType = {
  criado_em: string;
  data_entrega: string;
  codigo: string;
};

type SupplierType = {
  FK_fabrica: number;
  FK_fornecedor: string;
  SK_embalagem_fornecedor: number;
  composicao: string;
  nome_completo: string;
  nome_reduzido: string;
  codigo: string;
  lead_time: string | number;
  quantidade_por_palete: string | number;
} & OptionsType &
  SupplierTypeMeta;

export type ProductsWithProductionAndPackagingType = {
  FK_produto?: string;
  SK_embalagem?: string;
  ativo?: boolean;
  composicao?: string;
  descricao?: string;
  filial?: string;
  meta: {
    saldo_inicial?: Array<RowType>;
    saldo_final?: Array<RowType>;
    fornecedores?: Array<SupplierType>;
    previsao_consumo?: Array<RowType>;
    consumo_real?: Array<RowType>;
    consumo_acumulado?: Array<RowType>;
    coleta_programada?: Array<RowType>;
    coleta_real?: Array<RowType>;
    coleta_acumulada?: Array<RowType>;
  };
};

export type ProductionSimulationType = {
  FK_fabrica: number;
  FK_planejamento_demanda: number;
  SK_simulacao: number;
  descricao: string;
  codigo: string;
  periodo_fim: string;
  periodo_inicio: string;
  produtos_selecionados?: string;
  recursos_selecionados?: string;
  valores_linhas?: string;
};

export type PackagingSimulationResponseType = {
  meta: PackagingSimulationMetaDataType;
  embalagens: ProductsWithProductionAndPackagingType[];
  simulacao_producao: ProductionSimulationType;
  observacao?: string;
};

export type PackagingSimulationMutationType = {
  SK_simulacao?: number;
  observacao?: string;
  meta?: {
    utiliza_simulador_produto_acabado?: boolean;
    utiliza_valor_fornecedor_plano_embalagem?: boolean;
    base?: string;
    FK_plano_producao?: number;
    FK_plano_embalagem?: number;
    periodo_inicio?: string;
    periodo_fim?: string;
  };
};

export default function usePackagingSimulationMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (packagingData: PackagingSimulationMutationType) => {
      const response = await instance.put<
        ResponseDataType<PackagingSimulationResponseType>
      >("/planningbeer/packagingSimulation", {
        ...packagingData,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: packagingSimulatorKeys.all,
      });
    },
  });
}
