import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import CreateProductionPlanDialog from "./components/Dialog/CreateProductionPlanDialog";
import useFactories from "@/hooks/useFactories";
import Table from "@/components/NewTable";
import {
  ProductionPlanType,
  useProductionPlans,
} from "@/hooks/useProductionPlans";
import { TableData } from "@/components/NewTable/type";
import { ColumnOrderState, createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import ActionCell from "@/components/Cell/ActionCell";
import ActionDropdown from "./components/Dropdown/ActionDropdown";
import { useState } from "react";
import { getTableColumnOrder } from "@/utils/table";
import { useProductionPlanDeleteMutation } from "@/hooks/useProductionPlanDeleteMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import { useProductionPlanContext } from "./context";
import { DeleteDialogAlert } from "@/components/DialogAlert/DeleteDialogAlert";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { useRunProductionPlanEtlMutation } from "./hooks/useRunProductionPlanEtl";

const TABLE_ORDER_KEY = "productionPlanTable";
export type ProductionPlanTableType = TableData<ProductionPlanType>;
const columnHelper = createColumnHelper<ProductionPlanTableType>();

export default function ProductionPlan() {
  useFactories();
  const {
    clear,
    productionPlan: { SK_plano_producao },
    dialogType,
    visibleDialog,
  } = useProductionPlanContext();
  const { mutateAsync: runEtlMutateAsync, isPending: runEtlIsPending } =
    useRunProductionPlanEtlMutation();
  const { mutateAsync, isPending } = useProductionPlanDeleteMutation();
  const { data } = useProductionPlans();
  const { data: productionPlans = [] } = data || {};

  const columns = [
    columnHelper.accessor("SK_plano_producao", {
      header: "ID",
      id: "SK_plano_producao",
      size: 80,
    }),
    columnHelper.accessor("descricao", {
      header: "Descrição",
      id: "descricao",
      size: 350,
    }),
    columnHelper.accessor("revisao", {
      header: "Versão",
      id: "revisao",
      size: 120,
      cell: ({
        row: {
          original: { revisao },
        },
      }) => {
        return (
          <Text color="black" className="bg-gray-300 px-2 py-1 rounded">
            v{revisao}
          </Text>
        );
      },
    }),
    columnHelper.accessor("codigo", {
      header: "Código da Fábrica",
      id: "codigo",
      size: 200,
    }),
    columnHelper.accessor("abreviatura_empresa", {
      header: "Abreviatura da Fábrica",
      id: "abreviatura_empresa",
      size: 300,
    }),
    columnHelper.accessor("periodo_inicio", {
      header: "Período de Início",
      id: "periodo_inicio",
      size: 180,
      cell: ({ row }) => {
        const { periodo_inicio } = row.original;
        return dayjs(periodo_inicio).format("DD/MM/YYYY");
      },
    }),
    columnHelper.accessor("periodo_fim", {
      header: "Período de Fim",
      id: "periodo_fim",
      size: 180,
      cell: ({ row }) => {
        const { periodo_fim } = row.original;
        return dayjs(periodo_fim).format("DD/MM/YYYY");
      },
    }),
    columnHelper.accessor("criado_por", {
      header: "Criado por",
      id: "criado_por",
      size: 300,
    }),
    columnHelper.display({
      header: "Ações",
      id: "Ações",
      size: 100,
      cell: ({ row }) => {
        return <ActionCell items={<ActionDropdown {...row.original} />} />;
      },
    }),
  ];

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    getTableColumnOrder({ column: columns, key: TABLE_ORDER_KEY })
  );

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  async function handleDelete() {
    if (isPending) return;
    try {
      await mutateAsync({ id: Number(SK_plano_producao) });
      clear();
      return toast.success("Plano de produção excluído com sucesso!");
    } catch (error) {
      return validationError(error);
    }
  }

  async function handleRunEtl() {
    if (runEtlIsPending) return;
    const id = toast.loading("Executando extrações...");
    try {
      await runEtlMutateAsync();
      toast.success("Extrações executadas com sucesso!");
    } catch (err) {
      return validationError(err);
    } finally {
      toast.dismiss(id);
    }
  }

  return (
    <AuthenticatedLayout>
      <Row justify="end" className="gap-2">
        <Button
          variant="outline"
          disabled={runEtlIsPending}
          onClick={handleRunEtl}
        >
          <Text>Executar Extrações</Text>
        </Button>
        <CreateProductionPlanDialog />
      </Row>
      <Table<ProductionPlanTableType>
        columns={columns}
        data={productionPlans}
        meta={{
          layout: "stretch",
        }}
        columnOrderState={columnOrder}
        onColumnOrderStateChange={handleColumnOrder}
      />
      <DeleteDialogAlert
        onConfirm={handleDelete}
        visibleDialog={visibleDialog}
        handleVisibleDialog={clear}
        onCancel={clear}
        type={dialogType}
        alertDialogTitle={`Deseja excluir o plano de produção, "${SK_plano_producao}"?. Essa ação é irreversível.`}
      />
    </AuthenticatedLayout>
  );
}
