import instance from "@/api/business";
import { useMutation } from "@tanstack/react-query";

async function fetchRunProductionPlanEtl() {
  return await instance.post("/planningbeer/productionPlan/executeEtl");
}

export function useRunProductionPlanEtlMutation() {
  return useMutation({
    mutationFn: fetchRunProductionPlanEtl,
  });
}
