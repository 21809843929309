import { DemandEstimateResponseType } from "@/hooks/useProductionSimulationMutation";
import { CustomDemandPlanningType } from "../../context";

type GetDemandPlanningProps = {
  fkProduct: string;
  customDemandPlanning?: CustomDemandPlanningType;
  demandPlanning: DemandEstimateResponseType[];
  day: string;
};

export function getDemandPlanning({
  customDemandPlanning,
  fkProduct,
  demandPlanning,
  day,
}: GetDemandPlanningProps) {
  if (!day) return 0;
  const demandPlanningProductFiltered = demandPlanning.filter(
    ({ FK_produto }) => FK_produto == fkProduct
  );

  const currentYearMonth = day.substring(0, 7);
  if (demandPlanningProductFiltered.length > 0) {
    const product = demandPlanningProductFiltered[0];
    const quantitiesForMonth = product?.quantidades_por_mes?.[currentYearMonth];
    const customDemandPlanningValue =
      customDemandPlanning?.[fkProduct + day]?.demand;

    const value =
      customDemandPlanningValue || quantitiesForMonth?.quantidade_diaria;

    if (value) {
      return value;
    } else {
      return 0;
    }
  }

  return 0;
}
