import Can from "@/components/Can";
import Col from "@/components/Col";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  CellLineValuesType,
  ProductRealProductionType,
} from "@/hooks/useProductionSimulationMutation";
import useProductsDetails, {
  ProductsDetailsType,
} from "@/hooks/useProductsDetails";
import ProgrammedRealProductionTableHeader from "@/pages/PlanoProducao/components/Header/ProgrammedRealProductionTableHeader";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";
import { isBetweenDates } from "@/utils/date";
import { calculateHectoliters, quantityFormater } from "@/utils/number";
import dayjs from "dayjs";
import { useState } from "react";

type ProductionItem = { FK_produto: string; valor?: string };

type ProductionGroup = Record<
  string,
  {
    quantity: number;
    volume: number;
    totalHl: number;
    name: string;
    tendency?: number;
    tendencyHl?: number;
  }
>;

function groupByProduct<T extends ProductionItem>(
  items: T[],
  productDetails: ProductsDetailsType[]
) {
  const accumulated = items.reduce((acc, item) => {
    const product = productDetails.find(
      (productDetail) => productDetail.SK_produto == item.FK_produto
    );
    const { FK_produto, valor } = item;

    if (!acc[FK_produto]) {
      acc[FK_produto] = { quantity: 0, volume: 0, totalHl: 0, name: "" };
    }

    acc[FK_produto].quantity += Number(valor);
    acc[FK_produto].volume = Number(product?.volume);
    acc[FK_produto].name = product?.descricao || "";

    return acc;
  }, {} as ProductionGroup);

  Object.keys(accumulated).forEach((key) => {
    const product = accumulated[key];
    product.totalHl = calculateHectoliters(product.quantity, product.volume);
  });

  return accumulated;
}

function fetchUpcomingProductions(
  programmedProduction: CellLineValuesType[],
  productDetails: ProductsDetailsType[]
) {
  const currentDay = dayjs();

  const postCurrentDayProductions = programmedProduction.filter((value) => {
    return currentDay.isBefore(dayjs(value.dia));
  });

  const productTendencyStats = postCurrentDayProductions.reduce(
    (acc, item) => {
      const product = productDetails.find(
        (productDetail) => productDetail.SK_produto == item.FK_produto
      );
      if (!acc[item.FK_produto]) {
        acc[item.FK_produto] = { tendency: 0, tendencyHl: 0, volume: "" };
      }

      acc[item.FK_produto].tendency += Number(item.valor);
      acc[item.FK_produto].volume = product?.volume || "";

      return acc;
    },
    {} as Record<
      string,
      { tendency: number; tendencyHl: number; volume: string }
    >
  );

  return productTendencyStats;
}

function calculateProduction(
  realProduction: ProductRealProductionType[],
  programmedProduction: CellLineValuesType[],
  productDetails: ProductsDetailsType[],
  initialPeriod: string,
  finalPeriod: string
) {
  const filteredProgrammedByDate = programmedProduction.filter((item) => {
    return isBetweenDates({
      date: item.dia,
      initialPeriod: initialPeriod,
      finalPeriod: finalPeriod,
    });
  });

  const totalRealProductionPerProduct = groupByProduct(
    realProduction,
    productDetails
  );
  const totalProgrammedProductionPerProduct = groupByProduct(
    filteredProgrammedByDate,
    productDetails
  );

  const upcomingProductions = fetchUpcomingProductions(
    filteredProgrammedByDate,
    productDetails
  );

  const allProductsId = new Set([
    ...Object.keys(totalRealProductionPerProduct),
    ...Object.keys(totalProgrammedProductionPerProduct),
  ]);

  const sortedIds = Array.from(allProductsId).sort((a, b) => {
    const programmedA = totalProgrammedProductionPerProduct[a]?.quantity || 0;
    const programmedB = totalProgrammedProductionPerProduct[b]?.quantity || 0;
    return programmedB - programmedA;
  });

  const totalRealProduction = Object.values(
    totalRealProductionPerProduct
  ).reduce((acc, curr) => (acc += curr.quantity), 0);
  const totalRealProductionHl = Object.values(
    totalRealProductionPerProduct
  ).reduce((acc, curr) => (acc += curr.totalHl), 0);

  const totalProgrammedProduction = Object.values(
    totalProgrammedProductionPerProduct
  ).reduce((acc, curr) => (acc += curr.quantity), 0);
  const totalProgrammedProductionHl = Object.values(
    totalProgrammedProductionPerProduct
  ).reduce((acc, curr) => (acc += curr.totalHl), 0);

  Object.keys(totalProgrammedProductionPerProduct).forEach((key) => {
    const realProduction = totalRealProductionPerProduct[key];
    const programmed = totalProgrammedProductionPerProduct[key];
    const upcoming = upcomingProductions[key];

    const totalTendency = realProduction?.quantity + upcoming?.tendency;
    const totalTendencyHl = calculateHectoliters(
      totalTendency,
      programmed.volume
    );

    if (upcoming) {
      programmed.tendency = totalTendency;
      programmed.tendencyHl = totalTendencyHl;
    }
  });

  return {
    real: totalRealProductionPerProduct,
    programmed: totalProgrammedProductionPerProduct,
    totalRealProduction,
    totalRealProductionHl,
    totalProgrammedProduction,
    totalProgrammedProductionHl,
    ids: sortedIds,
  };
}

export default function ProgrammedRealProductionModal() {
  const [items, setItems] = useState<{
    real: ProductionGroup;
    programmed: ProductionGroup;
    ids: string[];
    totalRealProduction: number;
    totalRealProductionHl: number;
    totalProgrammedProduction: number;
    totalProgrammedProductionHl: number;
  }>({
    real: {},
    programmed: {},
    ids: [],
    totalProgrammedProduction: 0,
    totalProgrammedProductionHl: 0,
    totalRealProduction: 0,
    totalRealProductionHl: 0,
  });
  const {
    lines: { lineValues },
    simulationData: { simulador_producao },
  } = useSimulationProductionContext();
  const {
    produtos_producao_real: realProduction = [],
    periodo_inicio: initialPeriod,
    periodo_fim: finalPeriod,
  } = simulador_producao || {};
  const { data: productDetailsData } = useProductsDetails();
  const { data: productDetails = [] } = productDetailsData || {};

  return (
    <Dialog>
      <DialogTrigger>
        <Button
          size="sm"
          variant="outline"
          onClick={() => {
            const values = calculateProduction(
              realProduction,
              lineValues,
              productDetails,
              initialPeriod,
              finalPeriod
            );
            setItems(values);
          }}
        >
          <Text>Programado / Real</Text>
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col max-w-full h-full w-11/12 px-8 py-8">
        <Col className="h-full overflow-y-scroll">
          {items?.ids?.map((id, key) => {
            return (
              <Row className="w-full gap-2" key={id}>
                <Col className="w-full">
                  <Can
                    condition={key == 0}
                    onTrue={
                      <ProgrammedRealProductionTableHeader
                        title={
                          <>
                            <Row
                              justify="center"
                              align="center"
                              className="mb-14"
                            >
                              <Text className="mr-1 text-sm">Produtos</Text>
                            </Row>
                          </>
                        }
                      />
                    }
                  />
                  <Row className="p-2 border w-full">
                    <Text>
                      {id} -{" "}
                      {items?.programmed?.[id]?.name || items?.real?.[id]?.name}
                    </Text>
                  </Row>
                </Col>
                <Col className="w-full">
                  <Can
                    condition={key == 0}
                    onTrue={
                      <ProgrammedRealProductionTableHeader
                        rowTitle={{
                          first: "Quantidade",
                          second: "HL",
                          third: "Tendência",
                          fourthy: "Tendência HL",
                        }}
                        title={
                          <>
                            <Row justify="center" align="center">
                              <Text className="mr-1 text-sm">
                                Produção Programada
                              </Text>
                            </Row>
                            <Text className="mt-2">
                              <strong>Quantidade: </strong>
                              {quantityFormater(
                                items?.totalProgrammedProduction || 0
                              )}
                            </Text>
                            <Text className="mt-2">
                              <strong>Quantidade HL: </strong>
                              {quantityFormater(
                                items?.totalProgrammedProductionHl || 0
                              )}
                            </Text>
                          </>
                        }
                      />
                    }
                  />
                  <Row
                    className="w-full border-1 border h-full"
                    align="center"
                    justify="center"
                  >
                    <Col className="w-full items-center bg-gray-100 border-r-2 h-full justify-center">
                      <Text>
                        {quantityFormater(
                          items?.programmed?.[id]?.quantity || 0
                        )}
                      </Text>
                    </Col>
                    <Col className="w-full items-center border-r-2 h-full justify-center">
                      <Text>
                        {quantityFormater(
                          items?.programmed?.[id]?.totalHl || 0
                        )}
                      </Text>
                    </Col>
                    <Col className="w-full items-center bg-gray-100 border-r-2 h-full justify-center">
                      <Text>
                        {quantityFormater(
                          items?.programmed?.[id]?.tendency || 0
                        )}
                      </Text>
                    </Col>
                    <Col className="w-full items-center h-full justify-center">
                      <Text>
                        {quantityFormater(
                          items?.programmed?.[id]?.tendencyHl || 0
                        )}
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col className="w-full">
                  <Can
                    condition={key == 0}
                    onTrue={
                      <ProgrammedRealProductionTableHeader
                        title={
                          <>
                            <Row justify="center" align="center">
                              <Text className="mr-1 text-sm">
                                Produção Real
                              </Text>
                            </Row>
                            <Text className="mt-2">
                              <strong>Quantidade: </strong>
                              {quantityFormater(
                                items?.totalRealProduction || 0
                              )}
                            </Text>
                            <Text className="mt-2">
                              <strong>Quantidade HL: </strong>
                              {quantityFormater(
                                items?.totalRealProductionHl || 0
                              )}
                            </Text>
                          </>
                        }
                      />
                    }
                  />
                  <Row
                    className="w-full border-1 border h-full"
                    align="center"
                    justify="center"
                  >
                    <Col className="w-full items-center border-r-2 h-full justify-center">
                      <Text>
                        {quantityFormater(items?.real?.[id]?.quantity || 0)}
                      </Text>
                    </Col>
                    <Col className="w-full items-center  h-full justify-center">
                      <Text>
                        {quantityFormater(items?.real?.[id]?.totalHl || 0)}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
        </Col>
      </DialogContent>
    </Dialog>
  );
}
