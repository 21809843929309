import { GetPackagingDecreaseInventoryProps } from "../types";

export function getPackaginDecreaseInventory({
  composition,
  day,
  packagingDecreaseInventory,
}: GetPackagingDecreaseInventoryProps) {
  const decrease = packagingDecreaseInventory
    .filter((item) => item.FK_embalagem == composition && day == item.dia)
    .reduce((acc, item) => acc + item.quantidade, 0);

  return { decrease };
}
