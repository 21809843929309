import Can from "@/components/Can";
import Each from "@/components/Each";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";
import { TEXT_COLOR } from "@/constants";
import { Eye, EyeOff } from "lucide-react";
import React from "react";

type VisibleRowsProps = {
  filter: string[];
  sortedUniqueValues: string[];
  setFilter: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function VisibleRows({
  filter,
  setFilter,
  sortedUniqueValues,
}: VisibleRowsProps) {
  return (
    <React.Fragment>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Eye
            className="mb-1"
            size={22}
            color={filter.length > 0 ? "orange" : TEXT_COLOR}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="max-h-[600px]">
          <div className="max-h-[400px] max-w-[500px] overflow-y-scroll">
            <Each
              array={sortedUniqueValues}
              renderItem={(value) => {
                if (value == null) return null;
                return (
                  <>
                    <Row align="center" className="gap-2 px-2 my-2">
                      <Can
                        condition={filter.includes(value)}
                        onTrue={
                          <Eye
                            size={16}
                            color={"orange"}
                            onClick={() => {
                              if (filter.includes(value)) {
                                setFilter(filter.filter((v) => v !== value));
                              } else {
                                setFilter((prev) => [...prev, value]);
                              }
                            }}
                          />
                        }
                        onFalse={
                          <EyeOff
                            size={16}
                            color={TEXT_COLOR}
                            onClick={() => {
                              if (filter.includes(value)) {
                                setFilter(filter.filter((v) => v !== value));
                              } else {
                                setFilter((prev) => [...prev, value]);
                              }
                            }}
                          />
                        }
                      />
                      <Text className="text-[12px]">{value}</Text>
                    </Row>
                    <Separator />
                  </>
                );
              }}
            />
          </div>
          <Button
            size="sm"
            className="w-full"
            variant="outline"
            onClick={() => setFilter([])}
          >
            <Text>Limpar</Text>
          </Button>
        </DropdownMenuContent>
      </DropdownMenu>
    </React.Fragment>
  );
}
