import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { EMPTY_VALUE, ROUTES, TEXT_COLOR } from "@/constants";
import { useState } from "react";
import { MdFileUpload } from "react-icons/md";
import { usePackagingPlanContext } from "../../context";
import { usePackagingPlanSimulators } from "../../hooks/usePackagingPlanSimulators";
import { Separator } from "@/components/ui/separator";
import Col from "@/components/Col";
import Can from "@/components/Can";
import { SimulatorsEmpty } from "@/pages/PlanoProducao/components/Empty/SimulatorsEmpty";
import Each from "@/components/Each";
import { Checkbox } from "@/components/ui/checkbox";
import dayjs from "dayjs";
import { LuAlertTriangle } from "react-icons/lu";
import { formatSupplierValuesToString } from "../../utils/supplierValues";
import { usePackagingPlanAddSimulation } from "../../hooks/usePackagingPlanAddSimulation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

type SelectedSimulatorType = {
  SK_simulacao_embalagem: number;
  valores_fornecedores: string;
  observacao?: string;
};

export default function IncludeSimulatorModal() {
  const navigate = useNavigate();
  const [selectedSimulator, setSelectedSimulator] = useState<
    Array<SelectedSimulatorType>
  >([]);
  const [visible, setVisible] = useState(false);
  const {
    packagingPlan: {
      SK_plano_embalagem,
      versao: packagingPlanVersion,
      valores_fornecedores,
    },
  } = usePackagingPlanContext();
  const { mutateAsync, isPending } = usePackagingPlanAddSimulation();
  const { data } = usePackagingPlanSimulators(SK_plano_embalagem);
  const { data: packagingPlanSimulators = [] } = data || {};

  function handleSelectedSimulator(value: SelectedSimulatorType) {
    setSelectedSimulator([value]);
  }

  async function handleIncludeSimulator() {
    try {
      if (isPending) return;

      await mutateAsync({
        revision: packagingPlanVersion,
        fkPackagingPlan: SK_plano_embalagem,
        fkPackagingSimulation: selectedSimulator[0].SK_simulacao_embalagem,
        supplierValues: selectedSimulator[0].valores_fornecedores,
        observation: selectedSimulator[0].observacao,
        oldSupplierValues: valores_fornecedores,
      });
      toast.success("Simulador incluído com sucesso!");
      setVisible(!visible);
      setSelectedSimulator([]);
      return navigate("/" + ROUTES.packagingPlan.index);
    } catch (err) {
      return validationError(err);
    }
  }

  return (
    <Dialog
      onOpenChange={() => {
        setVisible(!visible);
        setSelectedSimulator([]);
      }}
      open={visible}
    >
      <DialogTrigger>
        <Button
          variant="outline"
          onClick={() => setVisible(!visible)}
          size="sm"
        >
          <Row className="justify-center items-center">
            <MdFileUpload size={22} color={TEXT_COLOR} />
            <Text className="ml-1 mt-1">Incluir Simulador</Text>
          </Row>
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col max-w-full h-full w-11/12 px-8 py-8">
        <Text className="text-lg">
          <strong>Versão do Plano: </strong>
          {packagingPlanVersion}
        </Text>
        <Separator />
        <Col className="h-full">
          <Text className="text-center mb-4">Simuladores Disponíveis</Text>
          <Can
            condition={packagingPlanSimulators.length == EMPTY_VALUE}
            onTrue={<SimulatorsEmpty />}
          />
          <Each
            array={packagingPlanSimulators}
            renderItem={({
              revisao,
              observacao,
              criado_por_nome,
              created_at,
              SK_simulacao_embalagem,
              valores_fornecedores,
              foi_incluido_simulador_producao,
            }) => {
              const isIncluded = foi_incluido_simulador_producao;
              const isSameRevision = packagingPlanVersion == revisao;
              return (
                <Row
                  className="border p-4 mb-4 rounded-sm"
                  align="center"
                  justify="space-between"
                >
                  <Row align="center">
                    <Checkbox
                      className="mr-4"
                      disabled={!isSameRevision || !isIncluded}
                      onClick={() =>
                        handleSelectedSimulator({
                          SK_simulacao_embalagem,
                          valores_fornecedores:
                            formatSupplierValuesToString(valores_fornecedores),
                          observacao,
                        })
                      }
                      checked={selectedSimulator.some(
                        (simulator) =>
                          simulator.SK_simulacao_embalagem ==
                          SK_simulacao_embalagem
                      )}
                    />
                    <div className="flex flex-col gap-1">
                      <Text>
                        Versão do simulador: <strong>{revisao}</strong>
                      </Text>
                      <Text>
                        Criado por: <strong>{criado_por_nome}</strong>
                      </Text>
                      <Text>
                        Criado em:{" "}
                        <strong>
                          {dayjs(created_at).format("DD/MM/YYYY HH:mm:ss")}
                        </strong>
                      </Text>
                      <Text>
                        Observação: <strong>{observacao}</strong>
                      </Text>
                      <Can
                        condition={!isSameRevision}
                        onTrue={
                          <Row className="mt-1">
                            <LuAlertTriangle
                              color={TEXT_COLOR}
                              className="mr-1"
                            />
                            <Text color="primary">
                              Este simulador está em uma versão incompatível com
                              o plano de ressuprimento de embalagens
                            </Text>
                          </Row>
                        }
                      />
                      <Can
                        condition={!isIncluded}
                        onTrue={
                          <Row className="mt-1">
                            <LuAlertTriangle
                              color={TEXT_COLOR}
                              className="mr-1"
                            />
                            <Text color="primary">
                              O simulador de Produto Acabado ainda não foi
                              incluído ao Plano de Produção
                            </Text>
                          </Row>
                        }
                      />
                    </div>
                  </Row>
                </Row>
              );
            }}
          />
        </Col>
        <Separator />
        <Row justify="end" className="gap-4">
          <Button variant="outline" onClick={() => setVisible(!visible)}>
            Cancelar
          </Button>
          <Button
            onClick={handleIncludeSimulator}
            disabled={isPending || selectedSimulator.length == EMPTY_VALUE}
          >
            Incluir
          </Button>
        </Row>
      </DialogContent>
    </Dialog>
  );
}
