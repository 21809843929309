import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { TEXT_COLOR } from "@/constants";
import { CellLineValuesType } from "@/hooks/useProductionSimulationMutation";
import useProductsDetails, {
  ProductsDetailsType,
} from "@/hooks/useProductsDetails";
import useRealProduction, {
  RealProductionType,
} from "@/hooks/useRealProduction";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";
import { addDashToDate } from "@/utils/date";
import { calculateHectoliters } from "@/utils/number";
import dayjs from "dayjs";
import { useEffect } from "react";
import { FaDroplet } from "react-icons/fa6";
import { generateDailyHlSheet } from "../../utils/dailyHlSheet";

function processDayProduction(
  production: RealProductionType[] | CellLineValuesType[],
  productDetails: ProductsDetailsType[],
  day: string
) {
  return production
    .filter((line) => {
      const date = String(line.dia).includes("-")
        ? line.dia
        : addDashToDate(String(line.dia));
      return date === day;
    })
    .map((line) => {
      const product = productDetails.find(
        (product) => product.SK_produto === line.FK_produto
      );
      const volume = product?.volume || 0;
      const total = calculateHectoliters(
        Number(line?.valor || 0),
        Number(volume)
      );

      return {
        ...line,
        volume,
        total,
      };
    });
}

function getDatesBetween(startDate: string, endDate: string): string[] {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const dates: string[] = [];

  let current = start;

  while (current.isBefore(end) || current.isSame(end, "day")) {
    dates.push(current.format("YYYY-MM-DD"));
    current = current.add(1, "day");
  }

  return dates;
}

function calculateHlSimulatePerDay({
  finalPeriod,
  initialPeriod,
  productDetails,
  realProduction,
  simulatedProduction,
}: {
  finalPeriod: string;
  initialPeriod: string;
  realProduction: RealProductionType[];
  simulatedProduction: CellLineValuesType[];
  productDetails: ProductsDetailsType[];
}) {
  const days = getDatesBetween(initialPeriod, finalPeriod);

  const hlSimulatedPerDay = days.map((day) => {
    const realDay = processDayProduction(realProduction, productDetails, day);
    const simulatedDay = processDayProduction(
      simulatedProduction,
      productDetails,
      day
    );

    const totalReal = realDay.reduce((acc, curr) => acc + curr.total, 0);
    const totalSimulated = simulatedDay.reduce(
      (acc, curr) => acc + curr.total,
      0
    );

    return {
      day: day,
      real: totalReal,
      simulated: totalSimulated,
    };
  });

  generateDailyHlSheet({ data: hlSimulatedPerDay });
  return hlSimulatedPerDay;
}

export default function ExportDailyHlButton() {
  const {
    simulationData: {
      simulador_producao: { periodo_fim = "", periodo_inicio = "" },
      fabrica: { codigo },
    },
    lines: { lineValues },
  } = useSimulationProductionContext();
  const { data: productDetailsData } = useProductsDetails();
  const {
    data: realProductionData,
    isFetching: isFetchingRealProduction,
    refetch: refetchRealProduction,
  } = useRealProduction({
    initialPeriod: periodo_inicio,
    finalPeriod: periodo_fim,
    branchCode: codigo,
  });
  const { data: realProduction = [] } = realProductionData || {};
  const { data: productDetails = [] } = productDetailsData || {};

  useEffect(() => {
    refetchRealProduction();
  }, [periodo_fim, periodo_inicio, codigo]);

  if (isFetchingRealProduction) return <></>;
  return (
    <Button
      variant="ghost"
      className="w-full"
      onClick={() => {
        calculateHlSimulatePerDay({
          simulatedProduction: lineValues,
          finalPeriod: periodo_fim,
          initialPeriod: periodo_inicio,
          productDetails,
          realProduction,
        });
      }}
    >
      <Row align="center" justify="start" className="w-full">
        <FaDroplet size={16} color={TEXT_COLOR} />
        <Text className="mt-1 ml-1">Exportar HL Diário</Text>
      </Row>
    </Button>
  );
}
