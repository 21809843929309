import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import SimulationTable from "@/pages/Simulacoes/components/Table/SimulationTable";
import { Link, useParams } from "react-router-dom";
import { useProductionSimulator } from "@/hooks/useProductionSimulator";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";
import React, { useEffect } from "react";
import Row from "@/components/Row";
import EditSimulatorDialog from "../components/Dialog/EditSimulatorDialog";
import useFactories from "@/hooks/useFactories";
import useProducts from "@/hooks/useProducts";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";
import { ProductionSimulatorType } from "@/hooks/useProductionSimulatorMutation";
import { toast } from "sonner";
import { validationError } from "@/utils/api";
import useProductionSimulatorPatchMutation from "@/hooks/useProductionSimulatorPatchMutation";
import { isEmptyArray } from "@/utils/array";
import { useFactoriesResources } from "@/hooks/useFactoriesResources";
import TableActionRow from "@/pages/Simulacoes/components/Row/TableActionRow";
import Can from "@/components/Can";
import SimulationError from "../components/Error/SimulationError";
import DotsLoading from "@/components/Loading/DotsLoading";
import ProgrammedRealProductionModal from "../components/Modal/ProgrammedRealProductionModal";

export default function EditSimulator() {
  useFactories();
  useFactoriesResources();
  useProducts();
  const { id } = useParams();
  const {
    handleSimulationData,
    production: { handleAccumulatedProductionArray },
    simulationData,
    clear,
    lines: { lineValues, handleLineValuesArray },
    customDemandPlanning: { handleCustomDemandPlanning, customDemandPlanning },
  } = useSimulationProductionContext();
  const { simulador_producao, observacao } = simulationData || {};

  const {
    data,
    error,
    isPending: isPendingProductionSimulator,
  } = useProductionSimulator({
    id: String(id),
  });
  const { data: productionSimulation } = data || {};
  const { mutateAsync, isPending } = useProductionSimulatorPatchMutation();

  async function handleEditSimulator() {
    if (isPending) return;
    const {
      FK_fabrica,
      periodo_fim,
      periodo_inicio,
      planejamento_demanda,
      produtos_selecionados,
      recursos_selecionados,
      tipo_planejamento,
    } = simulador_producao;
    const skDemandPlannings = planejamento_demanda?.map(
      ({ SK_planejamento_demanda }) => SK_planejamento_demanda
    );

    const data: ProductionSimulatorType = {
      descricao: "Simulação de produção ",
      FK_fabrica: Number(FK_fabrica),
      periodo_fim: periodo_fim,
      periodo_inicio: periodo_inicio,
      valores_linhas: lineValues,
      planejamento_demanda: skDemandPlannings || [],
      produtos_selecionados: isEmptyArray(produtos_selecionados)
        ? undefined
        : produtos_selecionados,
      recursos_selecionados: isEmptyArray(recursos_selecionados)
        ? undefined
        : recursos_selecionados,
      observacao: observacao,
      tipo_planejamento,
      demanda_customizada: customDemandPlanning,
    };

    try {
      await mutateAsync({ SK_simulacao: Number(id), ...data });
      toast.success("Simulação salva com sucesso!");
    } catch (error) {
      validationError(error);
    }
  }

  useEffect(() => {
    if (!productionSimulation) return;
    handleAccumulatedProductionArray(
      productionSimulation.producao_real_acumulada
    );
    handleLineValuesArray(
      productionSimulation.simulador_producao.valores_linhas || []
    );
    handleCustomDemandPlanning(productionSimulation.demanda_customizada || []);

    if (Object.keys(simulationData).length == 0) {
      handleSimulationData(productionSimulation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionSimulation]);

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  if (isPendingProductionSimulator)
    return (
      <AuthenticatedLayout className="h-full">
        <DotsLoading />
      </AuthenticatedLayout>
    );

  return (
    <AuthenticatedLayout title="Editando Simulador de Produto Acabado">
      <Can
        condition={!error}
        onTrue={
          <React.Fragment>
            <Can
              condition={!isPendingProductionSimulator}
              onTrue={
                <React.Fragment>
                  <Row justify="end" align="center" className="gap-4">
                    <ProgrammedRealProductionModal />
                    <TableActionRow />
                    <EditSimulatorDialog />
                  </Row>
                  <SimulationTable />
                  <Row justify="end" className=" gap-4">
                    <Link to="/simuladores">
                      <Button variant="ghost" size="sm">
                        Voltar
                      </Button>
                    </Link>
                    <Button
                      onClick={handleEditSimulator}
                      disabled={isPending}
                      size="sm"
                    >
                      <Text color="white">Salvar</Text>
                    </Button>
                  </Row>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
        onFalse={<SimulationError />}
      />
    </AuthenticatedLayout>
  );
}
