import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export type GridSalesType = {
  SK_venda_grade: string;
  valor: string;
  dia: string;
  base: string;
  FK_produto: string;
  filial_origem: string;
  created_at: string;
};

export async function fetchGridSales() {
  const response = await instance.post<ApiResponse<GridSalesType>>(
    "/planningbeer/seeGridSale"
  );
  return response.data;
}

export function useGridSales() {
  return useQuery({
    queryKey: ["gridSales"],
    queryFn: fetchGridSales,
  });
}
