import { useForm } from "react-hook-form";
import SimulationForm from "..";
import { schema, SimulationFormType } from "../zod";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import useProductionSimulationMutation, {
  UseProductionSimulationMutationType,
} from "@/hooks/useProductionSimulationMutation";
import { validationError } from "@/utils/api";
import { DialogProps } from "@/types";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";
import { useEffect } from "react";
import { useProductionPlanContext } from "@/pages/PlanoProducao/context";
import { PLANNING_TYPE } from "@/constants";

export default function CreateSimulationForm({
  handleClose,
  visibleObservation = true,
}: DialogProps & { visibleObservation?: boolean }) {
  const { productionPlan } = useProductionPlanContext();
  const {
    handleSimulationData,
    lines: { handleLineValuesArray },
    production: { handleAccumulatedProductionArray },
    customDemandPlanning: { handleCustomDemandPlanning },
  } = useSimulationProductionContext();
  const { mutateAsync, isPending } = useProductionSimulationMutation();
  const form = useForm<SimulationFormType>({
    resolver: zodResolver(schema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  async function onSubmit(data: SimulationFormType) {
    if (isPending) return;
    const initialValidity = dayjs(data.periodo_inicio).format("YYYY-MM-DD");
    const finalValidity = dayjs(data.periodo_fim).format("YYYY-MM-DD");

    const correctedData: UseProductionSimulationMutationType = {
      FK_fabrica: data.FK_fabrica,
      periodo_inicio: initialValidity,
      periodo_fim: finalValidity,
      produtos_selecionados: data.produtos,
      recursos_selecionados: data.recursos,
      valores_linhas: productionPlan.valores_linhas || "[]",
      observacao: data.observacao,
      visualizar_base: data.visualizar_base,
      tipo_planejamento: data.tipo_planejamento || PLANNING_TYPE.COMMERCIAL,
    };

    try {
      const { data } = await mutateAsync(correctedData);
      handleSimulationData(data);
      handleAccumulatedProductionArray(data.producao_real_acumulada || []);
      handleLineValuesArray(data.simulador_producao.valores_linhas || []);
      handleCustomDemandPlanning(data.demanda_customizada || []);
      form.reset();
      return handleClose();
    } catch (error) {
      return validationError(error);
    }
  }

  useEffect(() => {
    if (Object.values(productionPlan).length != 0) {
      return form.setValue("FK_fabrica", String(productionPlan?.FK_fabrica));
    }
  }, [productionPlan]);

  return (
    <SimulationForm
      form={form}
      onSubmit={onSubmit}
      button={{ disabled: isPending, title: "Gerar" }}
      visibleObservation={visibleObservation}
    />
  );
}
