import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import CreateSimulationDialog from "./components/Dialog/CreateSimulationDialog";
import useFactories from "@/hooks/useFactories";
import SimulationTable from "./components/Table/SimulationTable";
import useProducts from "@/hooks/useProducts";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";
import { useSimulationProductionContext } from "./context";
import Can from "@/components/Can";
import useProductionSimulatorMutation, {
  ProductionSimulatorType,
} from "@/hooks/useProductionSimulatorMutation";
import { toast } from "sonner";
import { validationError } from "@/utils/api";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants";
import { useFactoriesResources } from "@/hooks/useFactoriesResources";
import { useEffect } from "react";
import TableActionRow from "./components/Row/TableActionRow";
import InvalidProductionPlanError from "./components/Error/InvalidProductionPlanError";

export default function Simulations() {
  useFactories();
  useFactoriesResources();
  useProducts();
  const {
    simulationData,
    clear,
    lines: { lineValues },
    customDemandPlanning: { customDemandPlanning },
    productionPlan: {
      productionPlan: { SK_plano_producao, revisao },
      handleProductionPlan,
    },
  } = useSimulationProductionContext();
  const { mutateAsync, isPending } = useProductionSimulatorMutation();
  const { simulador_producao, observacao } = simulationData;
  const navigate = useNavigate();

  function isEmpty() {
    if (simulador_producao) {
      return true;
    }
    return false;
  }

  async function handleCreateSimulator() {
    if (isPending) return;
    const {
      FK_fabrica,
      periodo_fim,
      periodo_inicio,
      planejamento_demanda,
      produtos_selecionados,
      recursos_selecionados,
    } = simulador_producao;
    const skDemandPlannings = planejamento_demanda?.map(
      ({ SK_planejamento_demanda }) => SK_planejamento_demanda
    );

    const data: ProductionSimulatorType = {
      descricao: "Simulação de produção ",
      FK_fabrica: Number(FK_fabrica),
      periodo_fim: periodo_fim,
      periodo_inicio: periodo_inicio,
      planejamento_demanda: skDemandPlannings || [],
      produtos_selecionados: produtos_selecionados,
      recursos_selecionados: recursos_selecionados,
      valores_linhas: lineValues,
      FK_plano_producao: SK_plano_producao,
      revisao: revisao,
      observacao: observacao,
      demanda_customizada: customDemandPlanning,
    };

    try {
      await mutateAsync(data);
      toast.success("Simulação salva com sucesso!");
      navigate("/" + ROUTES.simulators.index);
      handleProductionPlan({} as any);
      return clear();
    } catch (error) {
      validationError(error);
    }
  }

  useEffect(() => {
    clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!SK_plano_producao)
    return (
      <AuthenticatedLayout className="h-full">
        <InvalidProductionPlanError />
      </AuthenticatedLayout>
    );

  return (
    <AuthenticatedLayout title="Gerar Simulação de Produto Acabado">
      <Row justify="end" align="center" className="gap-4">
        <TableActionRow />
        <CreateSimulationDialog />
      </Row>
      <SimulationTable />
      <Can
        condition={isEmpty()}
        onTrue={
          <Row justify="end" className="mt-2">
            <Button
              onClick={handleCreateSimulator}
              disabled={isPending}
              size="sm"
            >
              <Text color="white">Salvar</Text>
            </Button>
          </Row>
        }
      />
    </AuthenticatedLayout>
  );
}
