import { PackagingSupplierCellValueType } from "@/hooks/usePackagingSimulationMutation";
import { CalculatePackagingSupplierDailyTotalProps } from "../types";
import { BASE, QUARENTINE_LINE } from "@/constants";

export function calculatePackagingSupplierDailyTotal({
  currentDay,
  composition,
  day,
  packagingSupplierCellValues,
  currentInventory,
  realCollection,
  isGrouped,
  selectedBase,
}: CalculatePackagingSupplierDailyTotalProps) {
  const isBase1 = selectedBase == BASE.BASE1;
  const isBase2 = selectedBase == BASE.BASE2;
  const realCollectionTotal = realCollection
    ?.filter(
      (packagingSupplierCellValue) =>
        packagingSupplierCellValue.produto == composition &&
        packagingSupplierCellValue.dia === day
    )
    .reduce((acc, curr) => acc + Number(curr.total), 0);

  if (realCollectionTotal) {
    return { total: realCollectionTotal + currentInventory };
  }

  if (currentDay.isAfter(day)) {
    return { total: currentInventory };
  }

  if (isGrouped) {
    const total = packagingSupplierCellValues
      .filter(
        (packagingSupplierCellValue) =>
          packagingSupplierCellValue.composicao == composition &&
          packagingSupplierCellValue.dia === day
      )
      .reduce((acc, curr) => acc + Number(curr.valor), 0);
    return { total: total + currentInventory };
  }

  if (isBase1) {
    const total = packagingSupplierCellValues
      .filter(
        (packagingSupplierCellValue) =>
          packagingSupplierCellValue.composicao == composition &&
          packagingSupplierCellValue.dia == day &&
          !packagingSupplierCellValue.FK_embalagem_fornecedor.toString().includes(
            QUARENTINE_LINE
          )
      )
      .reduce((acc, curr) => acc + Number(curr.valor), 0);
    return { total: total + currentInventory };
  }

  if (isBase2) {
    const total = packagingSupplierCellValues
      .filter(
        (packagingSupplierCellValue) =>
          packagingSupplierCellValue.composicao == composition &&
          packagingSupplierCellValue.dia == day &&
          packagingSupplierCellValue.FK_embalagem_fornecedor.toString().includes(
            QUARENTINE_LINE
          )
      )
      .reduce((acc, curr) => acc + Number(curr.valor), 0);
    return { total: total + currentInventory };
  }

  return { total: currentInventory };
}

export type PackagingPlanSupplierValueResponseType = {
  dia: string;
  valor: string;
  FK_fabrica: number;
  composicao: string;
  FK_simulacao_embalagem: number;
  FK_embalagem_fornecedor: number;
  SK_simulacao_embalagem_fornecedor: number;
  quantidade_palete_carga: number;
  quantidade_por_palete: number;
};

export function formatPackagingPlanSupplierValue(
  packaginPlanSupplierValue: Array<PackagingPlanSupplierValueResponseType>
): PackagingSupplierCellValueType[] {
  if (packaginPlanSupplierValue.length == 0) return [];

  const formated: PackagingSupplierCellValueType[] =
    packaginPlanSupplierValue.map((value) => {
      return {
        composicao: value.composicao,
        dia: value.dia,
        FK_embalagem_fornecedor: value.FK_embalagem_fornecedor,
        FK_fabrica: value.FK_fabrica,
        FK_simulacao_producao: value.FK_simulacao_embalagem,
        valor: value.valor,
        quantidade_por_palete: String(value.quantidade_por_palete),
      };
    });

  return formated;
}
