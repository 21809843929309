import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import { isAllowed } from "@/utils/can";
import UploadDialog from "./components/Dialog/UploadDialog";
import ApproveDialogAlert from "./components/DialogAlert/ApproveDialogAlert";
import AcceptDialogAlert from "./components/DialogAlert/AcceptDialogAlert";
import PublishDialogAlert from "./components/DialogAlert/PublishDialogAlert";
import DeleteDialogAlert from "./components/DialogAlert/DeleteDialogAlert";
import StatusDialog from "./components/Dialog/StatusDialog";
import Table from "@/components/NewTable";
import {
  ColumnFiltersState,
  ColumnOrderState,
  createColumnHelper,
  PaginationState,
} from "@tanstack/react-table";
import { TableData } from "@/components/NewTable/type";
import ForecastStatusCell from "./components/Cell/StatusCell";
import ActionDropdownItems from "./components/Dropdown/ActionDropdownItems";
import ActionCell from "@/components/Cell/ActionCell";
import useEstimates from "@/hooks/useEstimates";
// import PivotTableUI from "react-pivottable/PivotTableUI";
import { useMemo, useState } from "react";
import dayjs from "dayjs";
import TableContainer from "@/components/Container/TableContainer";
import Can from "@/components/Can";
import { getPermissionCookie } from "@/utils/cookies";
import RejectDialogAlert from "./components/DialogAlert/RejectDialogAlert";
import { BASE } from "@/constants";

export interface EstimatesColumnProps {
  nome_planejamento: string;
  SK_planejamento_demanda: number;
  status: string;
  descricao: string;
  nome_usuario: string;
  tipo: string;
  created_at: string;
  editado_por_usuario_nome: string;
  editado_por_usuario_codigo: string;
  data_ultima_edicao: string;
  base: string;
  foi_comercial: boolean;
}

const columnHelper = createColumnHelper<EstimatesColumnProps>();

type EstimateTable = TableData<EstimatesColumnProps>;

const TABLE_ORDER_KEY = "demandEstimateColumnOrder";

export default function Estimates() {
  const permission = getPermissionCookie();
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });
  const { data } = useEstimates({
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
    filters,
    role: permission,
  });
  const { data: estimates = [], meta = { total: 0, pages: 0 } } = data || {};
  const isLogistics = permission == "logistics";

  const columns = useMemo(() => {
    const defaultColumns = [
      columnHelper.accessor("SK_planejamento_demanda", {
        header: "ID",
        id: "PD.SK_planejamento_demanda",
        size: 100,
      }),
      columnHelper.accessor("nome_planejamento", {
        header: "Nome da Carga",
        id: "PD.nome_planejamento",
        size: 300,
      }),
      columnHelper.accessor("descricao", {
        header: "Descrição da carga",
        id: "PD.descricao",
        size: 350,
      }),
      columnHelper.accessor("tipo", {
        size: 150,
        header: "Tipo",
        id: "PD.tipo",
      }),
      columnHelper.accessor("nome_usuario", {
        header: "Enviado por",
        id: "PD.nome_usuario",
        size: 300,
      }),
      columnHelper.accessor("created_at", {
        header: "Data da carga",
        id: "PD.created_at",
        size: 170,
        cell: ({ row }) => {
          const { created_at: uploadAt } = row.original;
          const formatedDate = dayjs(uploadAt).format("DD/MM/YYYY HH:mm:ss");
          return <span>{formatedDate}</span>;
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        id: "PD.status",
        size: 150,
        cell: ({ row }) => {
          const { status } = row.original;
          return <ForecastStatusCell status={status?.toLowerCase()} />;
        },
      }),
      columnHelper.accessor("editado_por_usuario_nome", {
        header: "Editado por",
        id: "PDDO.nome_usuario",
        size: 200,
      }),
      columnHelper.accessor("data_ultima_edicao", {
        header: "Data da edição",
        id: "PDDO.created_at",
        size: 170,
        cell: ({ row }) => {
          const { data_ultima_edicao: lastEditionDate } = row.original;
          if (lastEditionDate === null) return;

          const formatedDate = dayjs(lastEditionDate).format(
            "DD/MM/YYYY HH:mm:ss"
          );
          return <span>{formatedDate}</span>;
        },
      }),
      columnHelper.display({
        id: "Ações",
        header: "Ações",
        size: 100,
        cell: ({ row }) => {
          return (
            <ActionCell items={<ActionDropdownItems {...row.original} />} />
          );
        },
      }),
    ];

    const logisticColumns = [
      columnHelper.accessor("SK_planejamento_demanda", {
        header: "ID",
        id: "PD.SK_planejamento_demanda",
        size: 100,
      }),
      columnHelper.accessor("base", {
        header: "Tipo",
        id: "PD.base",
        size: 120,
        cell: ({ row }) => {
          const { base } = row.original;
          return <span>{BASE.BASE1 == base ? "Padrão" : "Estratégico"}</span>;
        },
      }),
      columnHelper.accessor("foi_comercial", {
        header: "Feito por",
        id: "PD.nome_planejamento",
        size: 150,
        cell: ({ row }) => {
          const { foi_comercial } = row.original;
          return <span>{foi_comercial ? "Comercial" : "Logística"}</span>;
        },
      }),
      columnHelper.accessor("nome_planejamento", {
        header: "Nome da Carga",
        id: "PD.nome_planejamento",
        size: 300,
      }),
      columnHelper.accessor("descricao", {
        header: "Descrição da carga",
        id: "PD.descricao",
        size: 350,
      }),
      columnHelper.accessor("tipo", {
        size: 150,
        header: "Tipo",
        id: "PD.tipo",
      }),
      columnHelper.accessor("nome_usuario", {
        header: "Enviado por",
        id: "PD.nome_usuario",
        size: 300,
      }),
      columnHelper.accessor("created_at", {
        header: "Data da carga",
        id: "PD.created_at",
        size: 170,
        cell: ({ row }) => {
          const { created_at: uploadAt } = row.original;
          const formatedDate = dayjs(uploadAt).format("DD/MM/YYYY HH:mm:ss");
          return <span>{formatedDate}</span>;
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        id: "PD.status",
        size: 150,
        cell: ({ row }) => {
          const { status } = row.original;
          return <ForecastStatusCell status={status?.toLowerCase()} />;
        },
      }),
      columnHelper.accessor("editado_por_usuario_nome", {
        header: "Editado por",
        id: "PDDO.nome_usuario",
        size: 200,
      }),
      columnHelper.accessor("data_ultima_edicao", {
        header: "Data da edição",
        id: "PDDO.created_at",
        size: 170,
        cell: ({ row }) => {
          const { data_ultima_edicao: lastEditionDate } = row.original;
          if (lastEditionDate === null) return;

          const formatedDate = dayjs(lastEditionDate).format(
            "DD/MM/YYYY HH:mm:ss"
          );
          return <span>{formatedDate}</span>;
        },
      }),
      columnHelper.display({
        id: "Ações",
        header: "Ações",
        size: 100,
        cell: ({ row }) => {
          return (
            <ActionCell items={<ActionDropdownItems {...row.original} />} />
          );
        },
      }),
    ];

    if (isLogistics) {
      return logisticColumns;
    }

    return defaultColumns;
  }, [permission, data]);

  function getColumnOrder() {
    const demandColumnOrder = localStorage.getItem(TABLE_ORDER_KEY);
    if (demandColumnOrder) {
      return JSON.parse(demandColumnOrder);
    }
    return columns.map((column) => column.id || "");
  }

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  const [columnOrder, setColumnOrder] =
    useState<ColumnOrderState>(getColumnOrder());

  return (
    <AuthenticatedLayout>
      <Row align="end" justify="end" className="mb-4">
        <Can
          onTrue={<UploadDialog />}
          condition={isAllowed(["operationalCommercial", "logistics"])}
        />
      </Row>
      <TableContainer>
        <Table<EstimateTable>
          onColumnOrderStateChange={handleColumnOrder}
          columnOrderState={columnOrder}
          data={estimates}
          columns={columns}
          paginationState={{
            pageIndex: pagination.pageIndex,
            totalPages: meta.pages,
            totalItems: meta.total,
            pageSize: 100,
          }}
          meta={{
            layout: "stretch",
          }}
          columnFiltersState={filters}
          stripe={false}
          getRowId={(row) => row.SK_planejamento_demanda.toString()}
          onPaginationChange={({ pageIndex, pageSize }) => {
            setPagination({ pageIndex, pageSize });
          }}
          onColumnFiltersChange={(array) => {
            setFilters(array);
          }}
        />
      </TableContainer>
      <ApproveDialogAlert />
      <AcceptDialogAlert />
      <PublishDialogAlert />
      <DeleteDialogAlert />
      <StatusDialog />
      <RejectDialogAlert />
    </AuthenticatedLayout>
  );
}
