import Can from "@/components/Can";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";

type ProgrammedRealProductionTableHeaderProps = {
  title: React.ReactNode;
  rowTitle?: {
    first?: string;
    second?: string;
    third?: string;
    fourthy?: string;
  };
};

export default function ProgrammedRealProductionTableHeader({
  title,
  rowTitle = {
    first: "Quantidade",
    second: "HL",
  },
}: ProgrammedRealProductionTableHeaderProps) {
  return (
    <>
      <Row className="mb-4" justify="center" align="center">
        <Text>{title}</Text>
      </Row>
      <Row align="center" className="border-1 border w-full">
        <Text className="w-1/2 text-sm text-center font-bold">
          {rowTitle.first}
        </Text>
        <Text className="w-1/2 text-sm text-center font-bold">
          {rowTitle.second}
        </Text>
        <Can
          condition={!!rowTitle.third}
          onTrue={
            <Text className="w-1/2 text-sm text-center font-bold">
              {rowTitle.third}
            </Text>
          }
        />
        <Can
          condition={!!rowTitle.fourthy}
          onTrue={
            <Text className="w-1/2 text-sm text-center font-bold">
              {rowTitle.fourthy}
            </Text>
          }
        />
      </Row>
    </>
  );
}
