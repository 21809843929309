import dayjs from "dayjs";
import { utils, writeFile } from "xlsx";

export function generateDailyHlSheet({
  data,
}: {
  data: {
    day: string;
    real: number;
    simulated: number;
  }[];
}) {
  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet([[]]);

  ws["A1"] = { t: "s", v: "Data" };
  ws["B1"] = { t: "s", v: "HL Programado" };
  ws["C1"] = { t: "s", v: "HL Real" };

  const formatedData = data.map((item) => {
    const day = dayjs(item.day).format("DD/MM/YYYY");
    return [day, item.simulated, item.real];
  });

  utils.sheet_add_json(ws, formatedData, {
    origin: {
      r: 1,
      c: 0,
    },
    skipHeader: true,
  });
  utils.book_append_sheet(wb, ws, "HL_Diário");
  writeFile(wb, "HL_Diário.xlsx");
}
