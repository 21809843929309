import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { TEXT_COLOR } from "@/constants";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { IoIosFunnel } from "react-icons/io";
import { zodResolver } from "@hookform/resolvers/zod";
import SeePackagingPlanForm from "../Form/See";
import { schema, SeePackagingPlanFormType } from "../Form/See/zod";
import { usePackagingPlanContext } from "../../context";
import Text from "@/components/Text/Text";
import Row from "@/components/Row";
import { useWindowSize } from "usehooks-ts";

export default function FilterPackagingPlanDropdown() {
  const { width } = useWindowSize();
  const { handleFilterPackagingPlan } = usePackagingPlanContext();
  const [visible, setVisible] = useState(false);

  const form = useForm<SeePackagingPlanFormType>({
    resolver: zodResolver(schema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  return (
    <DropdownMenu open={visible} onOpenChange={() => setVisible(!visible)}>
      <DropdownMenuTrigger>
        <Button variant="outline" size="sm">
          <Row align="center">
            <IoIosFunnel size={20} color={TEXT_COLOR} />
            <Text
              className="mt-1 ml-1"
              style={{ display: width < 1300 ? "none" : "", color: TEXT_COLOR }}
            >
              Filtrar Plano
            </Text>
          </Row>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="p-4">
        <SeePackagingPlanForm
          form={form}
          onSubmit={() => {
            handleFilterPackagingPlan({
              periodo_fim: form.getValues().periodo_fim,
              periodo_inicio: form.getValues().periodo_inicio,
              base: form.getValues().base,
            });
            setVisible(!visible);
          }}
          button={{ disabled: false, title: "Filtrar" }}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
