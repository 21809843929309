import instance from "@/api/business";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { ApiResponse } from "./useEstimates";

export type ProductsDetailsType = {
  SK_produto: string;
  descricao: string;
  tipo_produto: string;
  grupo: string;
  classe_produto: string;
  classificacao: string;
  litragem: string;
  tipo_liquido: string;
  volume: string;
  formacao: string;
  camadas: string;
  lastro: string;
  tipo_planejador: string;
  created_at: string;
  updated_at: string;
  D_E_L_E_T_: null;
  ativo: number;
  fator_conversao: string;
  estoque_maximo: null;
  estoque_reabastecimento: null;
  estoque_minimo: null;
};

async function fetchProductsDetails() {
  const response = await instance.post<ApiResponse<ProductsDetailsType>>(
    "/planningbeer/products"
  );
  return response.data;
}

export default function useProductsDetails() {
  return useQuery({
    queryKey: ["productsDetails"],
    queryFn: () => fetchProductsDetails(),
    placeholderData: keepPreviousData,
  });
}
